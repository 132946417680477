<template>
  <div class="div">

    <template v-if="currentRole != 70 && currentRole != 60">
      <MainLayout>
        <template v-slot:navbar-title>
          Редактировать
        </template>
        <CreateFormLayout>
          <template v-slot:title__text>
            Детская группа
          </template>

          <template v-slot:title-flex-right>
            <div></div>
          </template>

          <template>
            <!--    Данные компании    -->
            <div>
              <FormCardTitle class="data__text">Данные группы</FormCardTitle>
              <FormInputBlock>
                <ValidationInputField
                    rules="required|number"
                    label="Номер детской группы"
                    v-model="number"
                    validate-name="номер детской группы"
                >
                </ValidationInputField>
                <ValidationInputField
                    rules="required"
                    label="Название группы"
                    v-model="name"
                    validate-name="название группы"
                />
                <ValidationInputField
                    rules="required"
                    label="Группа пользователей"
                    validate-name="группа пользователей"

                    v-model="groupName"
                ></ValidationInputField>
<!--                <ValidationAutocompleteField-->
<!--                    v-if="this.$store.getters.getCurrentRole !== 31"-->
<!--                    :disabled="true"-->
<!--                    :search-function="userGroupSearch"-->
<!--                    rules="required"-->
<!--                    v-model="groupName"-->
<!--                    validate-name="группа пользователей"-->
<!--                    label="Группа пользователей"-->
<!--                />-->
              </FormInputBlock>
            </div>

            <!--    Данные компании    -->
          </template>

          <template v-slot:btns="{ handleSubmit }">
            <a @click="$router.go(-1)" class="btn btn_color_white">
              Отмена
            </a>
            <v-btn
                type="submit"
                @click="handleSubmit(editChildrenGroup)"
                class="btn btn-green"
            >
              Сохранить
            </v-btn>
          </template>
        </CreateFormLayout>

        <v-overlay :value="loading" z-index="9999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </MainLayout>
    </template>
    <template v-else>
      <MainLayout>
        <template v-slot:navbar-title>
          Редактировать
        </template>
        <CreateFormLayout>
          <template v-slot:title__text>
            Группа пользователей
          </template>

          <template v-slot:title-flex-right>
            <div></div>
          </template>

          <template>
            <FormCardTitle class="data__text">Данные группы</FormCardTitle>
            <FormInputBlock>
              <ValidationInputField
                  rules="required|number"
                  label="Номер детской группы"
                  v-model="number"
                  validate-name="номер детской группы"
              >
              </ValidationInputField>
              <ValidationInputField
                  rules="required"
                  label="Название группы"
                  v-model="name"
                  validate-name="название группы"
              />
<!--              <ValidationAutocompleteField-->
<!--                  v-if="this.$store.getters.getCurrentRole !== 31"-->
<!--                  :disabled="true"-->
<!--                  :search-function="userGroupSearch"-->
<!--                  rules="required"-->
<!--                  v-model="groupName"-->
<!--                  validate-name="группа пользователей"-->
<!--                  label="Группа пользователей"-->
<!--              />-->
              <ValidationInputField
                  rules="required"
                  label="Группа пользователей"
                  validate-name="группа пользователей"
                  :disabled="true"
                  v-model="groupName"
              ></ValidationInputField>
            </FormInputBlock>


            <!--    Контроллеры группы     -->
            <div>
              <FormCardTitle class="data__text">
                <div>Контроллеры группы</div>
                <div>
                  <v-switch
                      class="switcher"
                      v-model="is_all"
                      label="Все контроллеры объекта"
                  />
                </div>
                <v-col v-if="!is_all && object">
                  <ValidationAutocompleteField
                      multiple
                      hide-selected
                      :search-function="controllerSearch"
                      label="Контроллер"
                      rules="required"
                      return-object
                      v-model="controllers"
                  >
                    <template v-slot:selection=""> </template>
                  </ValidationAutocompleteField>
                </v-col>
              </FormCardTitle>
              <div style="margin-top: 15px;" v-if="is_all"></div>
              <BasicTable
                  v-if="!is_all"
                  :header="[
              { text: 'ID', value: 'id' },
              { text: 'Название', value: 'text' },
              {
                text: 'Удалить',
                width: 10,
                renderFunction: () => `<a class='form__btn-delete'></a>`
              }
            ]"
                  :table-data="controllers"
                  @row_click="deleteControllers($event.id)"
              ></BasicTable>
            </div>
            <!--    Контроллеры группы    -->


            <!--    Тариф группы пользователей     -->
            <div>
              <FormCardTitle class="data__text">
                Тариф группы пользователей
              </FormCardTitle>
              <FormInputBlock v-if="object.type === 'kindergarten'">
<!--                <p v-if="isAllowEdit">Стоимость карты</p>-->

<!--                <v-spacer v-if="isAllowEdit"/>-->
                <ValidationInputField
                    v-model="cardPrices"
                    rules="required|number|isEmpty"
                    label="Стоимость карты"
                    validate-name="стоимость карты"
                />
                <ValidationSelectField
                    v-model="validity"
                    :items="[
                { text: '12', value: 12 },
                { text: '24', value: 24 },
                { text: '36', value: 36 },
                { text: '48', value: 48 },
                { text: '60 ', value: 60 }
              ]"
                    rules="required"
                    label="Срок действия, мес."
                    validate-name="срок действия"
                ></ValidationSelectField>
              </FormInputBlock>
              <FormInputBlock >
                <p>Стоимость тарифа для приложения</p>
                <v-spacer/>
                <v-spacer/>
                <ValidationInputField
                    :rules="role === 120 ? '' : 'required'"
                    v-model="tariff_name"
                    label="Название тарифа"
                    validate-name="название тарифа"
                />
                <v-spacer/>
                <v-spacer/>

                <!--            <ValidationInputField-->
                <!--              :rules="role === 120 ? '' : 'required|price'"-->
                <!--              prefix="₽"-->
                <!--              v-model="appPrice"-->
                <!--              label="Стоимость за 1 день"-->
                <!--              validate-name="стоимость за 1 день"-->
                <!--              :suffix="Math.ceil(appPrice * 30) + '₽'"-->
                <!--              :disabled="!isAllowEdit ? true : false"-->
                <!--            />-->
                <ValidationInputField
                    :rules="role === 120 ? '' : 'required|number|isEmpty'"
                    type="number"
                    v-model="app_price_3"
                    label="Стоимость за 3 месяца"
                    validate-name="Стоимость за 3 месяцев"
                    prefix="₽"
                />
                <ValidationInputField
                    :rules="role === 120 ? '' : 'required|number|isEmpty'"
                    type="number"
                    v-model="app_price_12"
                    label="Стоимость за 12 месяцев"
                    validate-name="Стоимость за 12 месяцев"
                    prefix="₽"
                />
              </FormInputBlock>
            </div>

            <!--    Тариф группы пользователей    -->
          </template>
          <template v-slot:btns="{ handleSubmit }">
            <a @click="$router.go(-1)" class="btn btn_color_white">
              Отмена
            </a>
            <v-btn
                type="submit"
                @click="handleSubmit(editChildrenGroup)"
                class="btn btn-green"
            >
              Сохранить
            </v-btn>
          </template>
        </CreateFormLayout>
        <v-overlay :value="loading" z-index="9999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </MainLayout>
    </template>
  </div>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import {getAccessGroupsRequest} from "@/helpers/api/access-group";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import moment from "moment/moment";
import {getObjectsClearRequest} from "@/helpers/api/objects";
import {getControllersRequest} from "@/helpers/api/controllers";
import BasicTable from "@/components/tables/BasicTable.vue";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField.vue";

export default {
  name: "EditChildrenGroupPage",
  components: {
    ValidationSelectField, BasicTable,
    ValidationAutocompleteField,
    ValidationInputField,
    FormInputBlock,
    FormCardTitle,
    CreateFormLayout,
    MainLayout
  },
  data() {
    return {
      loading: false,
      header: [
        {
          text: "Группа пользователей",
          value: "title"
        },
        {
          text: "Контроллеров",
          // value: "controllers"
          renderFunction: value =>
              `${value.controllers.length === 0 ? "-" : value.controllers}`
        },
        {
          text: "",
          renderFunction: () => {
            return `<button @click="" >delete</button>`;
          }
        }
      ],
      objectSearch: async value => {
        return (
            await getObjectsClearRequest({
              query: {search: value, limit: 1000}
            })
        ).data.results.map(el => ({...el, text: el.name, value: el}));
      },
      controllerSearch: async value => {
        let object = this.object ? {object: this.object.id} : {};
        return (
            await getControllersRequest({query: {search: value, ...object}})
        ).data.results.map(el => ({...el, text: el.name, value: el.id}));
      },
      userGroupSearch: async value => {
        const id = this.$store.getters.getKindergartenId;
        return (
            await getAccessGroupsRequest({
              query: {search: value, organization: id}
            })
        ).data.results.map(el => ({text: el.title, value: el.id}));
      }
    };
  },
  computed: {
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    sourceObject() {
      return this.$store.getters.getUserGroupSearch;
    },
    urlQuery() {
      return {
        id: this.$route.params.id
      };
    },
    number: {
      get() {
        return this.$store.getters.getEditChildrenGroup.number.value;
      },
      set(newValue) {
        this.$store.commit("changeEditChildrenGroup", {
          fieldName: "number",
          value: newValue
        });
      }
    },
    name: {
      get() {
        return this.$store.getters.getEditChildrenGroup.name.value;
      },
      set(newValue) {
        this.$store.commit("changeEditChildrenGroup", {
          fieldName: "name",
          value: newValue
        });
      }
    },
    groupName: {
      get() {
        return this.$store.getters.getEditChildrenGroup.number ? `Опекуны группы ${this.$store.getters.getEditChildrenGroup.number.value}` : "";
      },
      set(newValue) {
        this.$store.commit("setChildrenGroupsCreateForm", {
          fieldName: "group",
          value: newValue
        });
      }
    },
    group: {
      get() {
        return this.$store.getters.getEditChildrenGroup.group.value;
      },
      set(newValue) {
        return this.$store.commit("changeEditChildrenGroup", {
          fieldName: "group",
          value: newValue
        });
      }
    },
    setUserGroupSearch: {
      get() {
        return this.$store.getters.getUserGroupSearch.value;
      },
      set(newValue) {
        this.$store.commit("setValueUserSearch", {
          listName: "setUserSearch",
          value: newValue
        });
      }
    },
    threeDayCount: {
      get() {
        return Math.abs(
            moment()
                .startOf("month")
                .diff(
                    moment()
                        .add(3, "month")
                        .startOf("month"),
                    "day"
                )
        );
      }
    },
    twelveDayCount: {
      get() {
        return Math.abs(
            moment()
                .startOf("month")
                .diff(
                    moment()
                        .add(12, "month")
                        .startOf("month"),
                    "day"
                )
        );
      }
    },
    startTime: {
      get() {
        return this.$store.getters.getEditUserGroupForm.startTime.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "startTime",
          value: newValue
        });
      }
    },
    endTime: {
      get() {
        return this.$store.getters.getEditUserGroupForm.endTime.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "endTime",
          value: newValue
        });
      }
    },
    timeType: {
      get() {
        return this.$store.getters.getEditUserGroupForm.timeType.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "timeType",
          value: newValue
        });
      }
    },
    isMonday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.isMonday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "isMonday",
          value: newValue
        });
      }
    },
    isTuesday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.isTuesday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "isTuesday",
          value: newValue
        });
      }
    },
    isWednesday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.isWednesday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "isWednesday",
          value: newValue
        });
      }
    },
    isThursday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.isThursday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "isThursday",
          value: newValue
        });
      }
    },
    isFriday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.isFriday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "isFriday",
          value: newValue
        });
      }
    },
    isSaturday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.isSaturday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "isSaturday",
          value: newValue
        });
      }
    },
    isSunday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.isSunday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "isSunday",
          value: newValue
        });
      }
    },
    startMonday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.startMonday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "startMonday",
          value: newValue
        });
      }
    },
    startTuesday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.startTuesday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "startTuesday",
          value: newValue
        });
      }
    },
    startWednesday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.startWednesday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "startWednesday",
          value: newValue
        });
      }
    },
    startThursday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.startThursday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "startThursday",
          value: newValue
        });
      }
    },
    startFriday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.startFriday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "startFriday",
          value: newValue
        });
      }
    },
    startSaturday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.startSaturday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "startSaturday",
          value: newValue
        });
      }
    },
    startSunday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.startSunday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "startSunday",
          value: newValue
        });
      }
    },
    endMonday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.endMonday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "endMonday",
          value: newValue
        });
      }
    },
    endTuesday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.endTuesday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "endTuesday",
          value: newValue
        });
      }
    },
    endWednesday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.endWednesday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "endWednesday",
          value: newValue
        });
      }
    },
    endThursday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.endThursday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "endThursday",
          value: newValue
        });
      }
    },
    endFriday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.endFriday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "endFriday",
          value: newValue
        });
      }
    },
    endSaturday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.endSaturday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "endSaturday",
          value: newValue
        });
      }
    },
    endSunday: {
      get() {
        return this.$store.getters.getEditUserGroupForm.endSunday.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "endSunday",
          value: newValue
        });
      }
    },
    is_all: {
      get() {
        return this.$store.getters.getEditUserGroupForm.is_all.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "is_all",
          value: newValue
        });
      }
    },
    // isAllowEdit() {
    //   if (
    //       this.$store.getters.getGroupInfo.results.appprices_details.length == 0
    //   ) {
    //     return false;
    //   }
    //   return true;
    // },
    title: {
      get() {
        return this.$store.getters.getEditUserGroupForm.title.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "title",
          value: newValue
        });
      }
    },
    validity: {
      get() {
        return this.$store.getters.getEditUserGroupForm.duration.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "duration",
          value: newValue
        });
      }
    },

    tariff_name: {
      get() {
        return this.$store.getters.getEditUserGroupForm.tariff_name.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "tariff_name",
          value: newValue
        });
      }
    },
    organization: {
      get() {
        return this.$store.getters.getEditUserGroupForm.organization.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "organization",
          value: newValue
        });
      }
    },
    object: {
      get() {
        return this.$store.getters.getEditUserGroupForm.object.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "object",
          value: newValue
        });
      }
    },
    // appPrice: {
    //   get() {
    //     return this.$store.getters.getEditUserGroupForm.appPrice.value;
    //   },
    //   set(newValue) {
    //     this.$store.commit("changeEditUserGroupValue", {
    //       fieldName: "appPrice",
    //       value: newValue
    //     });
    //   }
    // },
    controllers: {
      get() {
        return this.$store.getters.getEditUserGroupForm.controllers.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "controllers",
          value: newValue
        });
      }
    },
    role: {
      get() {
        return this.$store.getters.getEditUserGroupForm.role.value;
      }
    },
    cardPrices: {
      get() {
        return this.$store.getters.getEditUserGroupForm.cardPrices.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "cardPrices",
          value: newValue.replace(/^0+/, "")
        });
      }
    },
    app_price_3: {
      get() {
        return this.$store.getters.getEditUserGroupForm.app_price_3.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "app_price_3",
          value: newValue.replace(/^0+/, "")
        });
      }
    },
    app_price_12: {
      get() {
        return this.$store.getters.getEditUserGroupForm.app_price_12.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserGroupValue", {
          fieldName: "app_price_12",
          value: newValue.replace(/^0+/, "")
        });
      }
    }
  },


  created() {
    // this.$store.dispatch("fetchUserGroupInfo").finally(() => {
    //   this.isDateLoaded = false;
    // });
    this.$store.dispatch("prepareEditUserGroup", {
      id: this.$store.getters.getChildrenGroupsInfo.group
    });
    this.$store.dispatch("prepareEditChildrenGroup", {
      id: this.$route.params.id
    });
  },
  methods: {
    editUserGroup() {
      let self = this;
      this.loading = true;
      this.$store
          .dispatch("editUserGroup", {
            id: this.$store.getters.getChildrenGroupsInfo.group
          })
          .then(async () => {
            if (self.is_all) {
              // добавляем все контроллеры если кнопка активна
              const allControllers = (await self.controllerSearch()).map(
                  el => el.id
              );
              const data = {
                controllers: allControllers
              };
              return this.$store.dispatch("addAllControllersToAccessGroup", {
                id: this.$store.getters.getChildrenGroupsInfo.group,
                data
              });
            } else {
              return;
            }
          })
          // .then(() => {
          //   this.$store.dispatch(
          //       "successNotification",
          //       actionMessage.edit(successMessageDictionary.userGroup)
          //   );
          // })
          .finally(() => (this.loading = false));
    },
    deleteControllers(id) {
      this.controllers = this.controllers.filter(el => el.id !== id);
    },
    addChildrenGroup(newValue) {
      this.$store.commit("addUserSearch", {
        listName: "userSearch",
        value: newValue
      });
    },
    editChildrenGroup() {
      this.loading = true;
      this.editUserGroup();
      if (this.$store.getters.getCurrentRole != 31) {
        localStorage.setItem("editUserGroupNumber", this.number);
        this.$store
            .dispatch("editChildrenGroup", {id: this.$route.params.id})
            .then(() => {
              this.$store
                  .dispatch("editUserGroup", {
                    id: this.$store.getters.getChildrenGroupsInfo.group
                  })
                  .then(() => {
                    this.$store.dispatch(
                        "successNotification",
                        actionMessage.edit(successMessageDictionary.childrenGroup)
                    );
                  });
            })
            .finally(() => {
              localStorage.removeItem("editUserGroupNumber");
              this.loading = false;
            });
      } else {
        this.$store
            .dispatch("editChildrenGroup", {id: this.$route.params.id})
            .then(() => {
              this.$store.dispatch(
                  "successNotification",
                  actionMessage.edit(successMessageDictionary.childrenGroup)
              );
            })
            .finally(() => (this.loading = false));
      }
    }
  }
};
</script>

<style scoped></style>
